<template>
  <div class="toggle">
    <a :href="labelLink" target="_blank">
      <strong>{{ label }}</strong>
    </a>

    <UToggle
        v-model="value"
        :ui="{ container: { base: 'dark:bg-white' }, base: 'items-center' }"
        size="xl"
    />
  </div>
</template>

<script lang="ts" setup>
interface Props {
  label: string;
  labelLink?: string;
  modelValue: boolean;
}

const props = defineProps<Props>();

const value = ref(props.modelValue);

const emit = defineEmits(['update:modelValue', 'toggle']);

watch(() => value.value, () => {
  emit('toggle', value.value);
});
</script>

<style lang="scss" scoped>
.toggle {
  display: flex;
  align-items: center;
  gap: 1rem;
  height: 1rem;
}
</style>